<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
  >
    <circle cx="22" cy="22" r="12" fill="#D9D9D9" />
    <circle cx="22" cy="22" r="12" fill="url(#paint0_radial_87_151)" />
    <defs>
      <radialGradient
        id="paint0_radial_87_151"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10 10) scale(24 24)"
      >
        <stop stop-color="#FFCC00" />
        <stop offset="0.5" stop-color="#FF5940" />
        <stop offset="1" stop-color="#7F40FF" />
      </radialGradient>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
svg {
    padding-top: 3px;
}
</style>
