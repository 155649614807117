<template>
    <div class="detailed-container">
        <div class="detailed-info">
            <div class="detailed-info_departments_block">
                <div class="detailed-info_departments">
                    <h1 class="detailed-info_title">#куда_ищем</h1>
                    <div class="detailed-info_contents">
                        <p class="detailed-info_text text_1">Микросервисы СМБ</p>
                        <p class="detailed-info_text text_2">Образовательная платформа "Содружество"</p>
                        <p class="detailed-info_text text_3">Продуктовая фабрика&nbsp;РБ</p>
                        <p class="detailed-info_text text_4">Поддержка подразделений</p>
                        <p class="detailed-info_text text_5">Импортозамещение</p>
                        <p class="detailed-info_text text_6">Развитие экосистемы и&nbsp;небанк. сервисов</p>
                        <p class="detailed-info_text text_7">Развитие безрисковых продуктов ДБО&nbsp;КБ</p>
                        <p class="detailed-info_text text_8">Кредитный конвейер</p>
                        <p class="detailed-info_text text_9">Развитие ДБО</p>
                        <p class="detailed-info_text text_10">Проекты ОПК</p>
                    </div>
                </div>
            </div>
            <div class="detailed-info_professions_block ">
                <div class="detailed-info_professions">
                    <h1 class="detailed-info_title">#кого_ищем</h1>
                    <div class="detailed-info_description">
                        <div class="detailed-info_column">
                            <p class="detailed-info_list"><b>AQA: </b>Java, Selenium, Cucumber, Load Testing</p>
                            <p class="detailed-info_list"><b>QA: </b>SQL, Jira, SOAP, XML/XLST, Postman, API</p>
                            <p class="detailed-info_list"><b>Корпоративная архитектура:</b> Archimate MSA (Micro Service
                                Architecture), Java, C# , UML, BPMN</p>
                            <p class="detailed-info_list"><b>Python: </b>Django, Apache Airflow, Apache Hadoop, PySpark</p>
                            <p class="detailed-info_list"><b>JavaScript: </b>Angular, React, Vue, HTML, TypeScript, Node.js,
                                Express.js</p>
                            <p class="detailed-info_list"><b>Java: </b>JavaCore (collections, inheritance), Hibernate,
                                Spring (IoC and DI), SOLID, REST API, SQL</p>
                        </div>
                        <div class="detailed-info_column">
                            <p class="detailed-info_list"><b>DevOps: </b>Nexus, Gitlab, Jenkins, Hashicorp, Vault,
                                SonarCube, Ansible, Salt, CI/CD, Bash, Python, Linux, Git</p>
                            <p class="detailed-info_list"><b>Мобильная разработка: </b>iOS, Android, Swift, Objective-C,
                                Kotlin, Java, Android SDK</p>
                            <p class="detailed-info_list"><b>CA: </b>SOAP/REST, JSON, XML/XSD, SQL, Jira, Confluence</p>
                            <p class="detailed-info_list"><b>C#: </b>.Net Core, .Net Framework, MS SQL, ASP.Net</p>
                            <p class="detailed-info_list"><b>Support: </b>Linux, CI/CD, Astra, AltLinux, RedOS, GitLab,
                                Jenkins, PostgreSQL, Oracle, Apache, Nginx, Prometheus, Zabbix Opensearch, RabbitMQ, IBM MQ,
                                Kafka</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="detailed-images" v-if="windowInnerWidth > 1919">
            <div class="detailed-images_colorIcons">
                <svg xmlns="http://www.w3.org/2000/svg" width="400" height="366" viewBox="0 0 400 366" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M62.2112 45.681C62.2112 40.0255 66.7941 35.4408 72.4473 35.4408C78.1005 35.4408 82.6833 40.0255 82.6833 45.681H92.9193V76.4014H51.9752V45.681H62.2112ZM62.2112 66.1613H82.6833V55.9211H62.2112V66.1613Z"
                        fill="url(#paint0_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M113.391 117.362C113.391 128.673 122.557 137.842 133.863 137.842C145.17 137.842 154.336 128.673 154.336 117.362V96.8817H113.391V117.362ZM133.863 127.602C139.517 127.602 144.099 123.017 144.099 117.362V107.122H123.627V117.362C123.627 123.017 128.21 127.602 133.863 127.602Z"
                        fill="url(#paint1_linear_209_293)" />
                    <path d="M133.863 -10.6398H128.745V-5.51972H133.863V-10.6398Z" fill="url(#paint2_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M154.336 -26H113.391V14.9606H154.336V-26ZM144.099 -15.7599H123.627V4.72042H144.099V-15.7599Z"
                        fill="url(#paint3_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M81.4038 118.642L92.9193 107.122L51.9752 96.8817L62.2112 137.842L73.7268 126.322L85.2423 137.842L92.9193 130.162L81.4038 118.642ZM75.4004 111.976L67.0637 120.316L64.2848 109.196L75.4004 111.976Z"
                        fill="url(#paint4_linear_209_293)" />
                    <path
                        d="M11.0311 35.4408L4.63355 49.521L-9.441 55.9211L4.63355 62.3212L11.0311 76.4014L17.4286 62.3212L31.5031 55.9211L17.4286 49.521L11.0311 35.4408Z"
                        fill="url(#paint5_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M133.863 158.323L151.777 168.563V189.043L133.863 199.283L115.95 189.043V168.563L133.863 158.323ZM126.186 174.505L133.863 170.117L141.54 174.505V183.1L133.863 187.489L126.186 183.1V174.505Z"
                        fill="url(#paint6_linear_209_293)" />
                    <path
                        d="M13.151 158.323C10.4363 158.323 7.8327 159.401 5.91305 161.322L-7.94194 175.182L-0.703983 182.423L13.151 168.563H21.2671V176.682L7.41208 190.543L14.6501 197.783L28.5051 183.923C30.4247 182.002 31.5031 179.398 31.5031 176.682V158.323H13.151Z"
                        fill="url(#paint7_linear_209_293)" />
                    <path d="M3.35405 186.483V199.283H-9.441V186.483H3.35405Z" fill="url(#paint8_linear_209_293)" />
                    <path
                        d="M67.3292 158.323V173.683H51.9752V183.923H67.3292V199.283H77.5653V183.923H92.9193V173.683H77.5653V158.323H67.3292Z"
                        fill="url(#paint9_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M-9.441 96.8817V137.842H21.2671C26.9203 137.842 31.5031 133.258 31.5031 127.602V102.002H11.0311L5.91305 96.8817H-9.441ZM0.795045 127.602V112.242H21.2671V127.602H0.795045Z"
                        fill="url(#paint10_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M139.54 69.2796C137.074 70.5587 134.274 71.2813 131.304 71.2813C121.411 71.2813 113.391 63.2582 113.391 53.3611C113.391 43.464 121.411 35.4408 131.304 35.4408C141.198 35.4408 149.217 43.464 149.217 53.3611C149.217 56.3314 148.495 59.1329 147.217 61.5995L154.336 68.7213L146.659 76.4014L139.54 69.2796ZM138.981 53.3611C138.981 57.6027 135.544 61.0412 131.304 61.0412C127.065 61.0412 123.627 57.6027 123.627 53.3611C123.627 49.1195 127.065 45.681 131.304 45.681C135.544 45.681 138.981 49.1195 138.981 53.3611Z"
                        fill="url(#paint11_linear_209_293)" />
                    <path
                        d="M8.47208 -23.44H-3.04346L-12 -5.51972L-3.04346 12.4005H8.47208L-0.484456 -5.51972L8.47208 -23.44Z"
                        fill="url(#paint12_linear_209_293)" />
                    <path
                        d="M25.1056 -23.44H13.5901L22.5466 -5.51972L13.5901 12.4005H25.1056L34.0621 -5.51972L25.1056 -23.44Z"
                        fill="url(#paint13_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M92.9193 -10.6398C92.9193 -7.10366 91.486 -3.90243 89.1688 -1.58582L72.773 14.8222C72.5932 15.0023 72.3014 15.0023 72.1215 14.8222L55.7228 -1.58872C50.726 -6.5875 50.726 -14.6921 55.7228 -19.6909C60.2851 -24.255 67.4356 -24.6519 72.448 -20.8814C74.5863 -22.488 77.2442 -23.44 80.1243 -23.44C87.1908 -23.44 92.9193 -17.7091 92.9193 -10.6398ZM67.0653 -13.7199L72.4447 -9.67331L77.8266 -13.7168C78.4672 -14.1982 79.25 -14.4798 80.1243 -14.4798C82.2442 -14.4798 83.9628 -12.7606 83.9628 -10.6398C83.9628 -9.57651 83.5426 -8.62841 82.8376 -7.9236L72.4473 2.47453L62.056 -7.92448C60.557 -9.42411 60.557 -11.8555 62.056 -13.3551C63.4193 -14.719 65.5684 -14.8459 67.0653 -13.7199Z"
                        fill="url(#paint14_linear_209_293)" />
                    <path
                        d="M185.043 -26L174.807 -15.7599V4.72041C174.807 10.3759 179.39 14.9605 185.043 14.9605H192.72L202.956 4.72041H185.043V-26Z"
                        fill="url(#paint15_linear_209_293)" />
                    <path
                        d="M205.515 14.9606L215.751 4.72041V-15.7599C215.751 -21.4153 211.169 -26 205.515 -26H197.838L187.602 -15.7599L205.515 -15.7599V14.9606Z"
                        fill="url(#paint16_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M318.112 35.4408C312.459 35.4408 307.876 40.0255 307.876 45.681H297.64V76.4014H338.584V45.681H328.348C328.348 40.0255 323.765 35.4408 318.112 35.4408ZM307.876 66.1613V55.9211H328.348V66.1613H307.876Z"
                        fill="url(#paint17_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M210.633 112.242C210.633 115.7 209.491 118.89 207.564 121.458C212.536 125.194 215.751 131.142 215.751 137.842H205.515C205.515 132.187 200.933 127.602 195.279 127.602C189.626 127.602 185.043 132.187 185.043 137.842H174.807C174.807 131.142 178.023 125.194 182.995 121.458C181.067 118.89 179.925 115.7 179.925 112.242C179.925 103.759 186.8 96.8817 195.279 96.8817C203.759 96.8817 210.633 103.759 210.633 112.242ZM195.279 117.362C198.106 117.362 200.397 115.07 200.397 112.242C200.397 109.414 198.106 107.122 195.279 107.122C192.453 107.122 190.161 109.414 190.161 112.242C190.161 115.07 192.453 117.362 195.279 117.362Z"
                        fill="url(#paint18_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M215.751 35.4408H174.807V66.1613H185.043V76.4014L195.279 66.1613H205.515C211.169 66.1613 215.751 61.5766 215.751 55.9211V35.4408ZM205.515 45.681H185.043V55.9211H205.515V45.681Z"
                        fill="url(#paint19_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M379.528 137.842C368.222 137.842 359.056 128.673 359.056 117.362V96.8817H400V117.362C400 128.673 390.834 137.842 379.528 137.842ZM389.764 117.362C389.764 123.017 385.181 127.602 379.528 127.602C373.875 127.602 369.292 123.017 369.292 117.362V107.122H389.764V117.362Z"
                        fill="url(#paint20_linear_209_293)" />
                    <path d="M374.41 -10.6398H379.528V-5.51972H374.41V-10.6398Z" fill="url(#paint21_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M359.056 -26H400V14.9606H359.056V-26ZM369.292 -15.7599H389.764V4.72042H369.292V-15.7599Z"
                        fill="url(#paint22_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M338.584 107.122L327.068 118.642L338.584 130.162L330.907 137.842L319.391 126.322L307.876 137.842L297.64 96.8817L338.584 107.122ZM312.728 120.316L321.065 111.976L309.949 109.196L312.728 120.316Z"
                        fill="url(#paint23_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M215.751 170.808V199.283H174.807V170.808L195.279 158.316L215.751 170.808ZM185.043 176.557V189.043H205.515V176.557L195.279 170.31L185.043 176.557Z"
                        fill="url(#paint24_linear_209_293)" />
                    <path
                        d="M250.298 49.521L256.696 35.4408L263.093 49.521L277.168 55.9211L263.093 62.3212L256.696 76.4014L250.298 62.3212L236.223 55.9211L250.298 49.521Z"
                        fill="url(#paint25_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M397.441 168.563L379.528 158.323L361.615 168.563V189.043L379.528 199.283L397.441 189.043V168.563ZM379.528 170.117L371.851 174.505V183.1L379.528 187.489L387.205 183.1V174.505L379.528 170.117Z"
                        fill="url(#paint26_linear_209_293)" />
                    <path
                        d="M251.578 161.322C253.497 159.401 256.101 158.323 258.815 158.323H277.168V176.682C277.168 179.398 276.089 182.002 274.17 183.923L260.315 197.783L253.077 190.543L266.932 176.682V168.563H258.815L244.96 182.423L237.723 175.182L251.578 161.322Z"
                        fill="url(#paint27_linear_209_293)" />
                    <path d="M249.019 199.283V186.483H236.223V199.283H249.019Z" fill="url(#paint28_linear_209_293)" />
                    <path
                        d="M312.994 173.683V158.323H323.23V173.683H338.584V183.923H323.23V199.283H312.994V183.923H297.64V173.683H312.994Z"
                        fill="url(#paint29_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M236.223 137.842V96.8817H251.578L256.696 102.002H277.168V127.602C277.168 133.258 272.585 137.842 266.932 137.842H236.223ZM246.46 112.242V127.602H266.932V112.242H246.46Z"
                        fill="url(#paint30_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M376.969 71.2813C379.938 71.2813 382.738 70.5587 385.204 69.2796L392.323 76.4014L400 68.7213L392.881 61.5995C394.16 59.1329 394.882 56.3314 394.882 53.3611C394.882 43.464 386.862 35.4408 376.969 35.4408C367.076 35.4408 359.056 43.464 359.056 53.3611C359.056 63.2582 367.076 71.2813 376.969 71.2813ZM376.969 61.0412C381.209 61.0412 384.646 57.6027 384.646 53.3611C384.646 49.1195 381.209 45.681 376.969 45.681C372.729 45.681 369.292 49.1195 369.292 53.3611C369.292 57.6027 372.729 61.0412 376.969 61.0412Z"
                        fill="url(#paint31_linear_209_293)" />
                    <path
                        d="M242.621 -23.44H254.137L245.18 -5.51972L254.137 12.4005H242.621L233.664 -5.51972L242.621 -23.44Z"
                        fill="url(#paint32_linear_209_293)" />
                    <path
                        d="M259.255 -23.44H270.77L279.727 -5.51972L270.77 12.4005H259.255L268.211 -5.51972L259.255 -23.44Z"
                        fill="url(#paint33_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M334.833 -1.58582C337.15 -3.90243 338.584 -7.10366 338.584 -10.6398C338.584 -17.7091 332.855 -23.44 325.789 -23.44C322.909 -23.44 320.251 -22.488 318.112 -20.8814C313.1 -24.6519 305.95 -24.255 301.387 -19.6909C296.39 -14.6921 296.39 -6.5875 301.387 -1.58872L317.786 14.8222C317.966 15.0023 318.258 15.0023 318.437 14.8222L334.833 -1.58582ZM318.109 -9.67331L312.73 -13.7199C311.233 -14.8459 309.084 -14.719 307.72 -13.3551C306.221 -11.8555 306.221 -9.42411 307.72 -7.92448L318.112 2.47453L328.502 -7.9236C329.207 -8.62841 329.627 -9.57651 329.627 -10.6398C329.627 -12.7606 327.909 -14.4798 325.789 -14.4798C324.914 -14.4798 324.132 -14.1982 323.491 -13.7168L318.109 -9.67331Z"
                        fill="url(#paint34_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M62.2112 291.839C62.2112 286.183 66.7941 281.599 72.4473 281.599C78.1005 281.599 82.6833 286.183 82.6833 291.839H92.9193V322.559H51.9752V291.839H62.2112ZM62.2112 302.079V312.319H82.6833V302.079H62.2112Z"
                        fill="url(#paint35_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M113.391 363.52C113.391 374.831 122.557 384 133.863 384C145.17 384 154.336 374.831 154.336 363.52V343.039H113.391V363.52ZM133.863 373.76C139.517 373.76 144.099 369.175 144.099 363.52V353.28H123.627V363.52C123.627 369.175 128.21 373.76 133.863 373.76Z"
                        fill="url(#paint36_linear_209_293)" />
                    <path d="M133.863 235.518H128.745V240.638H133.863V235.518Z" fill="url(#paint37_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M154.336 220.158H113.391V261.118H154.336V220.158ZM144.099 230.398H123.627V250.878H144.099V230.398Z"
                        fill="url(#paint38_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M81.4038 364.8L92.9193 353.28L51.9752 343.039L62.2112 384L73.7268 372.48L85.2423 384L92.9193 376.32L81.4038 364.8ZM75.4004 358.134L67.0637 366.474L64.2848 355.354L75.4004 358.134Z"
                        fill="url(#paint39_linear_209_293)" />
                    <path
                        d="M11.0311 281.599L4.63355 295.679L-9.441 302.079L4.63355 308.479L11.0311 322.559L17.4286 308.479L31.5031 302.079L17.4286 295.679L11.0311 281.599Z"
                        fill="url(#paint40_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M-9.441 343.039V384H21.2671C26.9203 384 31.5031 379.415 31.5031 373.76V348.159H11.0311L5.91305 343.039H-9.441ZM0.795045 373.76V358.4H21.2671V373.76H0.795045Z"
                        fill="url(#paint41_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M139.54 315.437C137.074 316.716 134.274 317.439 131.304 317.439C121.411 317.439 113.391 309.416 113.391 299.519C113.391 289.622 121.411 281.599 131.304 281.599C141.198 281.599 149.217 289.622 149.217 299.519C149.217 302.489 148.495 305.291 147.217 307.757L154.336 314.879L146.659 322.559L139.54 315.437ZM138.981 299.519C138.981 303.76 135.544 307.199 131.304 307.199C127.065 307.199 123.627 303.76 123.627 299.519C123.627 295.277 127.065 291.839 131.304 291.839C135.544 291.839 138.981 295.277 138.981 299.519Z"
                        fill="url(#paint42_linear_209_293)" />
                    <path
                        d="M8.47208 222.718H-3.04346L-12 240.638L-3.04346 258.558H8.47208L-0.484456 240.638L8.47208 222.718Z"
                        fill="url(#paint43_linear_209_293)" />
                    <path
                        d="M25.1056 222.718H13.5901L22.5466 240.638L13.5901 258.558H25.1056L34.0621 240.638L25.1056 222.718Z"
                        fill="url(#paint44_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M92.9193 235.518C92.9193 239.054 91.486 242.255 89.1688 244.572L72.773 260.98C72.5932 261.16 72.3014 261.16 72.1215 260.98L55.7228 244.569C50.726 239.57 50.726 231.466 55.7228 226.467C60.2851 221.903 67.4356 221.506 72.448 225.276C74.5863 223.67 77.2442 222.718 80.1243 222.718C87.1908 222.718 92.9193 228.449 92.9193 235.518ZM67.0653 232.438L72.4447 236.484L77.8266 232.441C78.4672 231.96 79.25 231.678 80.1243 231.678C82.2442 231.678 83.9628 233.397 83.9628 235.518C83.9628 236.581 83.5426 237.529 82.8376 238.234L72.4473 248.632L62.056 238.233C60.557 236.734 60.557 234.302 62.056 232.803C63.4193 231.439 65.5684 231.312 67.0653 232.438Z"
                        fill="url(#paint45_linear_209_293)" />
                    <path
                        d="M185.043 220.158L174.807 230.398V250.878C174.807 256.534 179.39 261.118 185.043 261.118H192.72L202.956 250.878H185.043V220.158Z"
                        fill="url(#paint46_linear_209_293)" />
                    <path
                        d="M205.515 261.118L215.751 250.878V230.398C215.751 224.742 211.169 220.158 205.515 220.158H197.838L187.602 230.398H205.515V261.118Z"
                        fill="url(#paint47_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M318.112 281.599C312.459 281.599 307.876 286.183 307.876 291.839H297.64V322.559H338.584V291.839H328.348C328.348 286.183 323.765 281.599 318.112 281.599ZM307.876 312.319V302.079H328.348V312.319H307.876Z"
                        fill="url(#paint48_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M210.633 358.4C210.633 361.857 209.491 365.048 207.564 367.615C212.536 371.352 215.751 377.3 215.751 384H205.515C205.515 378.345 200.933 373.76 195.279 373.76C189.626 373.76 185.043 378.345 185.043 384H174.807C174.807 377.3 178.023 371.352 182.995 367.615C181.067 365.048 179.925 361.857 179.925 358.4C179.925 349.916 186.8 343.039 195.279 343.039C203.759 343.039 210.633 349.916 210.633 358.4ZM195.279 363.52C198.106 363.52 200.397 361.227 200.397 358.4C200.397 355.572 198.106 353.28 195.279 353.28C192.453 353.28 190.161 355.572 190.161 358.4C190.161 361.227 192.453 363.52 195.279 363.52Z"
                        fill="url(#paint49_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M215.751 281.599H174.807V312.319H185.043V322.559L195.279 312.319H205.515C211.169 312.319 215.751 307.734 215.751 302.079V281.599ZM205.515 291.839H185.043V302.079H205.515V291.839Z"
                        fill="url(#paint50_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M379.528 384C368.222 384 359.056 374.831 359.056 363.52V343.039H400V363.52C400 374.831 390.834 384 379.528 384ZM389.764 363.52C389.764 369.175 385.181 373.76 379.528 373.76C373.875 373.76 369.292 369.175 369.292 363.52V353.28H389.764V363.52Z"
                        fill="url(#paint51_linear_209_293)" />
                    <path d="M374.41 235.518H379.528V240.638H374.41V235.518Z" fill="url(#paint52_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M359.056 220.158H400V261.118H359.056V220.158ZM369.292 230.398H389.764V250.878H369.292V230.398Z"
                        fill="url(#paint53_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M338.584 353.28L327.068 364.8L338.584 376.32L330.907 384L319.391 372.48L307.876 384L297.64 343.039L338.584 353.28ZM312.728 366.474L321.065 358.134L309.949 355.354L312.728 366.474Z"
                        fill="url(#paint54_linear_209_293)" />
                    <path
                        d="M250.298 295.679L256.696 281.599L263.093 295.679L277.168 302.079L263.093 308.479L256.696 322.559L250.298 308.479L236.223 302.079L250.298 295.679Z"
                        fill="url(#paint55_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M236.223 384V343.039H251.578L256.696 348.159H277.168V373.76C277.168 379.415 272.585 384 266.932 384H236.223ZM246.46 358.4V373.76H266.932V358.4H246.46Z"
                        fill="url(#paint56_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M376.969 317.439C379.938 317.439 382.738 316.716 385.204 315.437L392.323 322.559L400 314.879L392.881 307.757C394.16 305.291 394.882 302.489 394.882 299.519C394.882 289.622 386.862 281.599 376.969 281.599C367.076 281.599 359.056 289.622 359.056 299.519C359.056 309.416 367.076 317.439 376.969 317.439ZM376.969 307.199C381.209 307.199 384.646 303.76 384.646 299.519C384.646 295.277 381.209 291.839 376.969 291.839C372.729 291.839 369.292 295.277 369.292 299.519C369.292 303.76 372.729 307.199 376.969 307.199Z"
                        fill="url(#paint57_linear_209_293)" />
                    <path
                        d="M242.621 222.718H254.137L245.18 240.638L254.137 258.558H242.621L233.664 240.638L242.621 222.718Z"
                        fill="url(#paint58_linear_209_293)" />
                    <path
                        d="M259.255 222.718H270.77L279.727 240.638L270.77 258.558H259.255L268.211 240.638L259.255 222.718Z"
                        fill="url(#paint59_linear_209_293)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M334.833 244.572C337.15 242.255 338.584 239.054 338.584 235.518C338.584 228.449 332.855 222.718 325.789 222.718C322.909 222.718 320.251 223.67 318.112 225.276C313.1 221.506 305.95 221.903 301.387 226.467C296.39 231.466 296.39 239.57 301.387 244.569L317.786 260.98C317.966 261.16 318.258 261.16 318.437 260.98L334.833 244.572ZM318.109 236.484L312.73 232.438C311.233 231.312 309.084 231.439 307.72 232.803C306.221 234.302 306.221 236.734 307.72 238.233L318.112 248.632L328.502 238.234C329.207 237.529 329.627 236.581 329.627 235.518C329.627 233.397 327.909 231.678 325.789 231.678C324.914 231.678 324.132 231.96 323.491 232.441L318.109 236.484Z"
                        fill="url(#paint60_linear_209_293)" />
                    <defs>
                        <linearGradient id="paint0_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint8_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint9_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint10_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint11_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint12_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint13_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint14_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint15_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint16_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint17_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint18_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint19_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint20_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint21_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint22_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint23_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint24_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint25_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint26_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint27_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint28_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint29_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint30_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint31_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint32_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint33_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint34_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint35_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint36_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint37_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint38_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint39_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint40_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint41_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint42_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint43_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint44_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint45_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint46_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint47_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint48_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint49_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint50_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint51_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint52_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint53_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint54_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint55_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint56_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint57_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint58_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint59_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                        <linearGradient id="paint60_linear_209_293" x1="-4.39534" y1="378.376" x2="373.304" y2="22.6376"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFCC01" />
                            <stop offset="0.53125" stop-color="#FF3819" />
                            <stop offset="1" stop-color="#8040FF" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="detailed-images_whiteIcons">
                <svg xmlns="http://www.w3.org/2000/svg" width="400" height="442" viewBox="0 0 400 442" fill="none">
                    <path
                        d="M-15 -5.55223L-4.55714 -16V15.3433H13.7179L3.27501 25.7911H-4.55713C-10.3246 25.7911 -15 21.1135 -15 15.3433V-5.55223Z"
                        fill="white" />
                    <path
                        d="M26.7715 15.3433L16.3286 25.7911L16.3286 -5.55223L-1.94642 -5.55223L8.49644 -16H16.3286C22.096 -16 26.7715 -11.3224 26.7715 -5.55223L26.7715 15.3433Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M120.757 57.1344C120.757 51.3643 125.433 46.6866 131.2 46.6866C136.968 46.6866 141.643 51.3643 141.643 57.1344H152.086V88.4777H110.314V57.1344H120.757ZM120.757 67.5822V78.03H141.643V67.5822H120.757Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M18.4186 134.448C20.3849 131.828 21.55 128.573 21.55 125.045C21.55 116.39 14.5369 109.373 5.88573 109.373C-2.76542 109.373 -9.77857 116.39 -9.77857 125.045C-9.77857 128.573 -8.61344 131.828 -6.64717 134.448C-11.7193 138.26 -15 144.329 -15 151.164H-4.55713C-4.55713 145.394 0.118296 140.717 5.88573 140.717C11.6532 140.717 16.3286 145.394 16.3286 151.164H26.7715C26.7715 144.329 23.4907 138.26 18.4186 134.448ZM11.1072 125.045C11.1072 127.93 8.76945 130.269 5.88573 130.269C3.00201 130.269 0.664298 127.93 0.664298 125.045C0.664298 122.16 3.00201 119.821 5.88573 119.821C8.76945 119.821 11.1072 122.16 11.1072 125.045Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M-15 46.6866H26.7715V67.5822C26.7715 73.3523 22.096 78.03 16.3286 78.03H5.88573L-4.55713 88.4777V78.03H-15V46.6866ZM-4.55713 57.1344H16.3286V67.5822H-4.55713V57.1344Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M172.972 130.269C172.972 141.809 182.322 151.164 193.857 151.164C205.392 151.164 214.743 141.809 214.743 130.269V109.373H172.972V130.269ZM193.857 140.717C199.625 140.717 204.3 136.039 204.3 130.269V119.821H183.414V130.269C183.414 136.039 188.09 140.717 193.857 140.717Z"
                        fill="white" />
                    <path d="M193.857 -0.328341H188.636V4.89555H193.857V-0.328341Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M214.743 -16H172.972V25.7911H214.743V-16ZM204.3 -5.55223H183.414V15.3433H204.3V-5.55223Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M140.338 131.575L152.086 119.821L110.314 109.373L120.757 151.164L132.505 139.411L144.254 151.164L152.086 143.329L140.338 131.575ZM134.213 124.774L125.708 133.283L122.873 121.937L134.213 124.774Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M26.7715 213.851V184.799L5.88573 172.053L-15 184.799V213.851H26.7715ZM-4.55713 203.403V190.664L5.88573 184.291L16.3286 190.664V203.403H-4.55713Z"
                        fill="white" />
                    <path
                        d="M68.5429 46.6866L62.0161 61.0523L47.6572 67.5822L62.0161 74.112L68.5429 88.4777L75.0697 74.112L89.4287 67.5822L75.0697 61.0523L68.5429 46.6866Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M193.857 172.06L212.132 182.508V203.403L193.857 213.851L175.582 203.403V182.508L193.857 172.06ZM186.025 188.571L193.857 184.093L201.689 188.571V197.34L193.857 201.818L186.025 197.34V188.571Z"
                        fill="white" />
                    <path
                        d="M70.7057 172.06C67.9361 172.06 65.2799 173.161 63.3215 175.12L49.1865 189.262L56.5707 196.649L70.7057 182.508H78.9858V190.792L64.8508 204.933L72.235 212.321L86.37 198.179C88.3284 196.22 89.4287 193.563 89.4287 190.792V172.06H70.7057Z"
                        fill="white" />
                    <path d="M60.7108 200.791V213.851H47.6572V200.791H60.7108Z" fill="white" />
                    <path
                        d="M125.979 172.06V187.732H110.314V198.179H125.979V213.851H136.422V198.179H152.086V187.732H136.422V172.06H125.979Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M47.6572 109.373V151.164H78.9858C84.7532 151.164 89.4287 146.487 89.4287 140.717V114.597H68.5429L63.3215 109.373H47.6572ZM58.1001 140.717V125.045H78.9858V140.717H58.1001Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M199.648 81.2115C197.133 82.5165 194.276 83.2538 191.247 83.2538C181.154 83.2538 172.972 75.068 172.972 64.9702C172.972 54.8725 181.154 46.6866 191.247 46.6866C201.34 46.6866 209.522 54.8725 209.522 64.9702C209.522 68.0008 208.785 70.8591 207.48 73.3757L214.743 80.6419L206.911 88.4777L199.648 81.2115ZM199.079 64.9702C199.079 69.2979 195.572 72.8061 191.247 72.8061C186.921 72.8061 183.414 69.2979 183.414 64.9702C183.414 60.6426 186.921 57.1344 191.247 57.1344C195.572 57.1344 199.079 60.6426 199.079 64.9702Z"
                        fill="white" />
                    <path
                        d="M65.9322 -13.3881H54.184L45.0465 4.89555L54.184 23.1791H65.9322L56.7947 4.89555L65.9322 -13.3881Z"
                        fill="white" />
                    <path
                        d="M82.9019 -13.3881H71.1536L80.2912 4.89555L71.1536 23.1791H82.9019L92.0394 4.89555L82.9019 -13.3881Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M152.086 -0.328356C152.086 3.27949 150.624 6.54563 148.26 8.90921L131.532 25.65C131.349 25.8336 131.051 25.8336 130.868 25.65L114.138 8.90625C109.04 3.80612 109.04 -4.46283 114.138 -9.56296C118.792 -14.2196 126.087 -14.6245 131.201 -10.7776C133.382 -12.4168 136.094 -13.3881 139.032 -13.3881C146.242 -13.3881 152.086 -7.54103 152.086 -0.328356ZM125.709 -3.47094L131.198 0.65774L136.688 -3.46776C137.342 -3.95886 138.14 -4.24626 139.032 -4.24626C141.195 -4.24626 142.948 -2.49215 142.948 -0.328356C142.948 0.756504 142.52 1.72382 141.8 2.44292L131.2 13.0519L120.599 2.44202C119.07 0.911985 119.07 -1.5687 120.599 -3.09873C121.99 -4.49023 124.182 -4.61978 125.709 -3.47094Z"
                        fill="white" />
                    <path
                        d="M235.628 -5.55223L246.071 -16V15.3433H264.346L253.904 25.7911H246.071C240.304 25.7911 235.628 21.1135 235.628 15.3433V-5.55223Z"
                        fill="white" />
                    <path
                        d="M277.4 15.3433L266.957 25.7911V-5.55223L248.682 -5.55223L259.125 -16H266.957C272.725 -16 277.4 -11.3224 277.4 -5.55223V15.3433Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M371.386 57.1344C371.386 51.3643 376.061 46.6866 381.829 46.6866C387.596 46.6866 392.271 51.3643 392.271 57.1344H402.714V88.4777H360.943V57.1344H371.386ZM371.386 78.03H392.271V67.5822H371.386V78.03Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M269.047 134.448C271.013 131.828 272.178 128.573 272.178 125.045C272.178 116.39 265.165 109.373 256.514 109.373C247.863 109.373 240.85 116.39 240.85 125.045C240.85 128.573 242.015 131.828 243.981 134.448C238.909 138.26 235.628 144.329 235.628 151.164H246.071C246.071 145.394 250.747 140.717 256.514 140.717C262.282 140.717 266.957 145.394 266.957 151.164H277.4C277.4 144.329 274.119 138.26 269.047 134.448ZM261.736 125.045C261.736 127.93 259.398 130.269 256.514 130.269C253.63 130.269 251.293 127.93 251.293 125.045C251.293 122.16 253.63 119.821 256.514 119.821C259.398 119.821 261.736 122.16 261.736 125.045Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M235.628 46.6866H277.4V67.5822C277.4 73.3523 272.725 78.03 266.957 78.03H256.514L246.071 88.4777V78.03H235.628V46.6866ZM246.071 57.1344H266.957V67.5822H246.071V57.1344Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M390.966 131.575L402.714 119.821L360.943 109.373L371.386 151.164L383.134 139.411L394.882 151.164L402.714 143.329L390.966 131.575ZM384.841 124.774L376.336 133.283L373.501 121.937L384.841 124.774Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M277.4 213.851V184.799L256.514 172.053L235.628 184.799V213.851H277.4ZM246.071 203.403V190.664L256.514 184.291L266.957 190.664V203.403H246.071Z"
                        fill="white" />
                    <path
                        d="M319.171 46.6866L312.645 61.0523L298.286 67.5822L312.645 74.112L319.171 88.4777L325.698 74.112L340.057 67.5822L325.698 61.0523L319.171 46.6866Z"
                        fill="white" />
                    <path
                        d="M321.334 172.06C318.565 172.06 315.908 173.161 313.95 175.12L299.815 189.262L307.199 196.649L321.334 182.508H329.614V190.792L315.479 204.933L322.864 212.321L336.999 198.179C338.957 196.22 340.057 193.563 340.057 190.792V172.06H321.334Z"
                        fill="white" />
                    <path d="M311.339 200.791V213.851H298.286V200.791H311.339Z" fill="white" />
                    <path
                        d="M376.607 172.06V187.732H360.943V198.179H376.607V213.851H387.05V198.179H402.714V187.732H387.05V172.06H376.607Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M298.286 109.373V151.164H329.614C335.382 151.164 340.057 146.487 340.057 140.717V114.597H319.171L313.95 109.373H298.286ZM308.729 140.717V125.045H329.614V140.717H308.729Z"
                        fill="white" />
                    <path
                        d="M316.561 -13.3881H304.812L295.675 4.89555L304.812 23.1791H316.561L307.423 4.89555L316.561 -13.3881Z"
                        fill="white" />
                    <path
                        d="M333.53 -13.3881H321.782L330.92 4.89555L321.782 23.1791H333.53L342.668 4.89555L333.53 -13.3881Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M402.714 -0.328356C402.714 3.27949 401.252 6.54563 398.888 8.90921L382.161 25.65C381.977 25.8336 381.68 25.8336 381.496 25.65L364.766 8.90625C359.668 3.80612 359.668 -4.46283 364.766 -9.56296C369.421 -14.2196 376.716 -14.6245 381.829 -10.7776C384.011 -12.4168 386.722 -13.3881 389.661 -13.3881C396.87 -13.3881 402.714 -7.54103 402.714 -0.328356ZM376.338 -3.47094L381.826 0.65774L387.317 -3.46776C387.97 -3.95886 388.769 -4.24626 389.661 -4.24626C391.824 -4.24626 393.577 -2.49215 393.577 -0.328356C393.577 0.756504 393.148 1.72382 392.429 2.44292L381.829 13.0519L371.227 2.44202C369.698 0.911985 369.698 -1.5687 371.227 -3.09873C372.618 -4.49023 374.811 -4.61978 376.338 -3.47094Z"
                        fill="white" />
                    <path
                        d="M235.628 245.597L246.071 235.149V266.492H264.346L253.904 276.94H246.071C240.304 276.94 235.628 272.262 235.628 266.492V245.597Z"
                        fill="white" />
                    <path
                        d="M277.4 266.492L266.957 276.94V245.597H248.682L259.125 235.149H266.957C272.725 235.149 277.4 239.827 277.4 245.597V266.492Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M371.386 308.283C371.386 302.513 376.061 297.836 381.829 297.836C387.596 297.836 392.271 302.513 392.271 308.283H402.714V339.627H360.943V308.283H371.386ZM371.386 318.731V329.179H392.271V318.731H371.386Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M269.047 385.596C271.013 382.977 272.178 379.722 272.178 376.194C272.178 367.539 265.165 360.522 256.514 360.522C247.863 360.522 240.85 367.539 240.85 376.194C240.85 379.722 242.015 382.977 243.981 385.596C238.909 389.409 235.628 395.478 235.628 402.313H246.071C246.071 396.543 250.747 391.866 256.514 391.866C262.282 391.866 266.957 396.543 266.957 402.313H277.4C277.4 395.478 274.119 389.409 269.047 385.596ZM261.736 376.194C261.736 379.079 259.398 381.418 256.514 381.418C253.63 381.418 251.293 379.079 251.293 376.194C251.293 373.309 253.63 370.97 256.514 370.97C259.398 370.97 261.736 373.309 261.736 376.194Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M235.628 297.836H277.4V318.731C277.4 324.501 272.725 329.179 266.957 329.179H256.514L246.071 339.627V329.179H235.628V297.836ZM246.071 308.283H266.957V318.731H246.071V308.283Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M390.966 382.724L402.714 370.97L360.943 360.522L371.386 402.313L383.134 390.56L394.882 402.313L402.714 394.477L390.966 382.724ZM384.841 375.923L376.336 384.432L373.501 373.086L384.841 375.923Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M277.4 465V435.948L256.514 423.202L235.628 435.948V465H277.4ZM246.071 454.552V441.813L256.514 435.44L266.957 441.813V454.552H246.071Z"
                        fill="white" />
                    <path
                        d="M319.171 297.836L312.645 312.201L298.286 318.731L312.645 325.261L319.171 339.627L325.698 325.261L340.057 318.731L325.698 312.201L319.171 297.836Z"
                        fill="white" />
                    <path
                        d="M321.334 423.209C318.565 423.209 315.908 424.31 313.95 426.269L299.815 440.411L307.199 447.798L321.334 433.657H329.614V441.941L315.479 456.082L322.864 463.47L336.999 449.328C338.957 447.369 340.057 444.711 340.057 441.941V423.209H321.334Z"
                        fill="white" />
                    <path d="M311.339 451.94V465H298.286V451.94H311.339Z" fill="white" />
                    <path
                        d="M376.607 423.209V438.88H360.943V449.328H376.607V465H387.05V449.328H402.714V438.88H387.05V423.209H376.607Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M298.286 360.522V402.313H329.614C335.382 402.313 340.057 397.636 340.057 391.866V365.746H319.171L313.95 360.522H298.286ZM308.729 391.866V376.194H329.614V391.866H308.729Z"
                        fill="white" />
                    <path
                        d="M316.561 237.761H304.812L295.675 256.044L304.812 274.328H316.561L307.423 256.044L316.561 237.761Z"
                        fill="white" />
                    <path d="M333.53 237.761H321.782L330.92 256.044L321.782 274.328H333.53L342.668 256.044L333.53 237.761Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M402.714 250.821C402.714 254.428 401.252 257.695 398.888 260.058L382.161 276.799C381.977 276.983 381.68 276.983 381.496 276.799L364.766 260.055C359.668 254.955 359.668 246.686 364.766 241.586C369.421 236.929 376.716 236.524 381.829 240.371C384.011 238.732 386.722 237.761 389.661 237.761C396.87 237.761 402.714 243.608 402.714 250.821ZM376.338 247.678L381.826 251.807L387.317 247.681C387.97 247.19 388.769 246.903 389.661 246.903C391.824 246.903 393.577 248.657 393.577 250.821C393.577 251.905 393.148 252.873 392.429 253.592L381.829 264.201L371.227 253.591C369.698 252.061 369.698 249.58 371.227 248.05C372.618 246.659 374.811 246.529 376.338 247.678Z"
                        fill="white" />
                    <path
                        d="M-15 245.597L-4.55714 235.149V266.492H13.7179L3.27501 276.94H-4.55713C-10.3246 276.94 -15 272.262 -15 266.492V245.597Z"
                        fill="white" />
                    <path
                        d="M26.7715 266.492L16.3286 276.94L16.3286 245.597H-1.94642L8.49644 235.149H16.3286C22.096 235.149 26.7715 239.827 26.7715 245.597L26.7715 266.492Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M120.757 308.283C120.757 302.513 125.433 297.836 131.2 297.836C136.968 297.836 141.643 302.513 141.643 308.283H152.086V339.627H110.314V308.283H120.757ZM120.757 318.731V329.179H141.643V318.731H120.757Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M18.4186 385.596C20.3849 382.977 21.55 379.722 21.55 376.194C21.55 367.539 14.5369 360.522 5.88573 360.522C-2.76542 360.522 -9.77857 367.539 -9.77857 376.194C-9.77857 379.722 -8.61344 382.977 -6.64717 385.596C-11.7193 389.409 -15 395.478 -15 402.313H-4.55713C-4.55713 396.543 0.118296 391.866 5.88573 391.866C11.6532 391.866 16.3286 396.543 16.3286 402.313H26.7715C26.7715 395.478 23.4907 389.409 18.4186 385.596ZM11.1072 376.194C11.1072 379.079 8.76945 381.418 5.88573 381.418C3.00201 381.418 0.664298 379.079 0.664298 376.194C0.664298 373.309 3.00201 370.97 5.88573 370.97C8.76945 370.97 11.1072 373.309 11.1072 376.194Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M-15 297.836H26.7715V318.731C26.7715 324.501 22.096 329.179 16.3286 329.179H5.88573L-4.55713 339.627V329.179H-15V297.836ZM-4.55713 308.283H16.3286V318.731H-4.55713V308.283Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M172.972 381.418C172.972 392.958 182.322 402.313 193.857 402.313C205.392 402.313 214.743 392.958 214.743 381.418V360.522H172.972V381.418ZM193.857 391.866C199.625 391.866 204.3 387.188 204.3 381.418V370.97H183.414V381.418C183.414 387.188 188.09 391.866 193.857 391.866Z"
                        fill="white" />
                    <path d="M193.857 250.821H188.636V256.045H193.857V250.821Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M214.743 235.149H172.972V276.94H214.743V235.149ZM204.3 245.597H183.414V266.492H204.3V245.597Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M140.338 382.724L152.086 370.97L110.314 360.522L120.757 402.313L132.505 390.56L144.254 402.313L152.086 394.478L140.338 382.724ZM134.213 375.923L125.708 384.432L122.873 373.086L134.213 375.923Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M26.7715 465V435.948L5.88573 423.202L-15 435.948V465H26.7715ZM-4.55713 454.552V441.813L5.88573 435.44L16.3286 441.813V454.552H-4.55713Z"
                        fill="white" />
                    <path
                        d="M68.5429 297.836L62.0161 312.201L47.6572 318.731L62.0161 325.261L68.5429 339.627L75.0697 325.261L89.4287 318.731L75.0697 312.201L68.5429 297.836Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M193.857 423.209L212.132 433.657V454.552L193.857 465L175.582 454.552V433.657L193.857 423.209ZM186.025 439.72L193.857 435.242L201.689 439.72V448.489L193.857 452.967L186.025 448.489V439.72Z"
                        fill="white" />
                    <path
                        d="M70.7057 423.209C67.9361 423.209 65.2799 424.31 63.3215 426.269L49.1865 440.411L56.5707 447.798L70.7057 433.657H78.9858V441.941L64.8508 456.082L72.235 463.47L86.37 449.328C88.3284 447.369 89.4287 444.712 89.4287 441.941V423.209H70.7057Z"
                        fill="white" />
                    <path d="M60.7108 451.94V465H47.6572V451.94H60.7108Z" fill="white" />
                    <path
                        d="M125.979 423.209V438.881H110.314V449.328H125.979V465H136.422V449.328H152.086V438.881H136.422V423.209H125.979Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M47.6572 360.522V402.313H78.9858C84.7532 402.313 89.4287 397.636 89.4287 391.866V365.746H68.5429L63.3215 360.522H47.6572ZM58.1001 391.866V376.194H78.9858V391.866H58.1001Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M199.648 332.36C197.133 333.666 194.276 334.403 191.247 334.403C181.154 334.403 172.972 326.217 172.972 316.119C172.972 306.021 181.154 297.836 191.247 297.836C201.34 297.836 209.522 306.021 209.522 316.119C209.522 319.15 208.785 322.008 207.48 324.525L214.743 331.791L206.911 339.627L199.648 332.36ZM199.079 316.119C199.079 320.447 195.572 323.955 191.247 323.955C186.921 323.955 183.414 320.447 183.414 316.119C183.414 311.792 186.921 308.283 191.247 308.283C195.572 308.283 199.079 311.792 199.079 316.119Z"
                        fill="white" />
                    <path
                        d="M65.9322 237.761H54.184L45.0465 256.045L54.184 274.328H65.9322L56.7947 256.045L65.9322 237.761Z"
                        fill="white" />
                    <path
                        d="M82.9019 237.761H71.1536L80.2912 256.045L71.1536 274.328H82.9019L92.0394 256.045L82.9019 237.761Z"
                        fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M152.086 250.821C152.086 254.428 150.624 257.695 148.26 260.058L131.532 276.799C131.349 276.983 131.051 276.983 130.868 276.799L114.138 260.055C109.04 254.955 109.04 246.686 114.138 241.586C118.792 236.929 126.087 236.524 131.201 240.371C133.382 238.732 136.094 237.761 139.032 237.761C146.242 237.761 152.086 243.608 152.086 250.821ZM125.709 247.678L131.198 251.807L136.688 247.681C137.342 247.19 138.14 246.903 139.032 246.903C141.195 246.903 142.948 248.657 142.948 250.821C142.948 251.905 142.52 252.873 141.8 253.592L131.2 264.201L120.599 253.591C119.07 252.061 119.07 249.58 120.599 248.05C121.99 246.659 124.182 246.529 125.709 247.678Z"
                        fill="white" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const windowInnerWidth = ref(window.innerWidth);
console.log('windowInnerWidth', windowInnerWidth.value)
onMounted(() => {
    window.addEventListener("resize", function () {
        windowInnerWidth.value = window.innerWidth;
    });
});
</script>

<style lang="scss" scoped>
.detailed-container {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 320px) {
        padding: 0 calc(50% - 155px) 40px;
    }

    @media screen and (min-width: 375px) {
        padding: 0 calc(50% - 177px) 50px;
    }

    @media screen and (min-width: 390px) {
        padding: 0 calc(50% - 187px) 60px;
    }

    @media screen and (min-width: 1024px) {
        padding: 0 calc(50% - 472px) 100px;
    }

    @media screen and (min-width: 1920px) {
        padding: 0 calc(50% - 880px) 120px;
    }
}

.detailed-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 320px) {
        row-gap: 22px;
    }

    @media screen and (min-width: 390px) {
        width: 373px;
    }

    @media screen and (min-width: 1024px) {
        width: 944px;
        row-gap: 40px;
    }

    @media screen and (min-width: 1920px) {
        width: 1320px;
        height: 848px;
    }

    &_departments_block {
        background: #fff;

        @media screen and (min-width: 320px) {
            min-height: 315px;
            width: 310px;
            padding: 16px 7px 16px;
            border-radius: 20px 20px 1px 20px;
        }

        @media screen and (min-width: 375px) {
            width: auto;
        }

        @media screen and (min-width: 1024px) {
            width: 944px;
            border-radius: 60px 60px 1px 60px;
            padding: 40px 17px 40px 30px;
        }

        @media screen and (min-width: 1920px) {
            width: 1320px;
            padding: 40px 60px 40px;
            border-radius: 100px 100px 1px 100px;
        }
    }

    &_professions_block {
        background: #fff;

        @media screen and (min-width: 320px) {
            height: auto;
            padding: 16px 7px 16px;
            border-radius: 20px 1px 20px 20px;
        }

        @media screen and (min-width: 390px) {
            height: 674px;
        }

        @media screen and (min-width: 1024px) {
            height: 440px;
            border-radius: 60px 1px 60px 60px;
            padding: 40px 43px 0 40px;
        }

        @media screen and (min-width: 1920px) {
            height: auto;
            padding: 40px 60px 27px;
            border-radius: 100px 1px 100px 100px;
        }
    }

    &_column {
        @media screen and (min-width: 390px) {
            width: 358px;
        }

        @media screen and (min-width: 1024px) {
            width: 418px;
        }

        @media screen and (min-width: 1920px) {
            width: 585px;
        }
    }

    &_title {
        color: #111;
        font-weight: 700;
        line-height: normal;

        @media screen and (min-width: 320px) {
            font-size: 24px;
            padding-left: 8px;
        }

        @media screen and (min-width: 1024px) {
            font-size: 32px;
            padding: 0;
        }

        @media screen and (min-width: 1920px) {
            font-size: 48px;
        }
    }

    &_contents {
        @media screen and (min-width: 320px) {
            display: grid;
            justify-items: start;
            row-gap: 12px;
            grid-template-columns: 1fr 103px;
            padding-top: 12px;
            grid-template-areas:
                'text_1  text_8'
                'text_2  text_2'
                'text_4  text_9'
                'text_5  text_10'
                'text_7  text_7'
                'text_3  text_3'
                'text_6  text_6'
            ;
        }

        @media screen and (min-width: 375px) {
            grid-template-columns: 191px 103px;
        }

        @media screen and (min-width: 390px) {
            grid-template-columns: 177px 181px;
        }

        @media screen and (min-width: 1024px) {
            display: flex;
            flex-wrap: wrap;
            justify-items: center;
            align-items: center;
            row-gap: 24px;
            column-gap: 12px;
            padding-top: 32px;
        }

        @media screen and (min-width: 1920px) {
            padding-top: 21px;
        }
    }

    &_text {
        color: #1b232f;
        line-height: normal;
        background: #f5f5f5;
        font-weight: 400;

        &.text_1 {
            grid-area: text_1;
        }

        &.text_2 {
            grid-area: text_2;
            display: flex;
            flex-wrap: nowrap;
        }

        &.text_3 {
            grid-area: text_3;
        }

        &.text_4 {
            grid-area: text_4;
            white-space: nowrap;
        }

        &.text_5 {
            grid-area: text_5;
        }

        &.text_6 {
            grid-area: text_6;
        }

        &.text_7 {
            grid-area: text_7;
        }

        &.text_8 {
            grid-area: text_8;

            @media screen and (min-width: 320px) {
                white-space: nowrap;
                position: relative;
                left: -44px;
            }

            @media screen and (min-width: 390px) {
                left: 0;
            }

            @media screen and (min-width: 1024px) {
                white-space: normal;
                position: static;
                left: 0;
            }
        }

        &.text_9 {
            grid-area: text_9;

            @media screen and (min-width: 390px) {
                position: relative;
                left: 58px;
            }

            @media screen and (min-width: 1024px) {
                position: static;
                left: 0;
            }
        }

        &.text_10 {
            grid-area: text_10;

            @media screen and (min-width: 320px) {
                position: relative;
                left: -44px;
            }

            @media screen and (min-width: 390px) {
                left: 0;
            }

            @media screen and (min-width: 1024px) {
                position: static;
                left: 0;
            }
        }

        @media screen and (min-width: 320px) {
            border-radius: 12px;
            padding: 4px 8px;
            font-size: 13px;
            height: fit-content;
        }

        @media screen and (min-width: 390px) {
            font-size: 16px;
        }

        @media screen and (min-width: 1024px) {
            border-radius: 39px;
            padding: 12px;
            font-size: 18px;
        }

        @media screen and (min-width: 1920px) {
            font-size: 24px;
        }
    }

    &_description {
        display: flex;
        justify-items: center;

        @media screen and (min-width: 320px) {
            flex-direction: column;
            padding-top: 12px
        }

        @media screen and (min-width: 1024px) {
            padding-top: 32px;
            flex-direction: row;
            justify-content: space-between;
        }

        @media screen and (min-width: 1920px) {
            padding-top: 23px;
        }
    }

    &_list {
        color: #1b232f;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 13px;

        @media screen and (min-width: 320px) {
            font-size: 13px;
        }

        @media screen and (min-width: 320px) {
            font-size: 16px;
        }

        @media screen and (min-width: 1024px) {
            font-size: 18px;
        }

        @media screen and (min-width: 1920px) {
            font-size: 20px;
        }
    }
}

.detailed-images {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 848px;

    &_colorIcons {
        width: 400px;
        height: 366px;
        border-radius: 100px 100px 100px 1px;
        border: 4px solid #fff;
        overflow: hidden;
    }

    &_whiteIcons {
        width: 400px;
        height: 442px;
        border-radius: 1px 100px 100px 100px;
        border: 4px solid #fff;
        overflow: hidden;
    }
}
</style>
